import React, { useState } from 'react';
import './App.css';
// import SnakeGame from './SnakeGame';
// import WhackAMole from './WhackAMole';
import MemoryMatch from './MemoryMatch';

// ... (keep the GameCard component)

function App() {
  const [currentGame, setCurrentGame] = useState(null);

  const games = [
    {
      //title: "Slithery Snacker",
      //description: "Eat pixels, grow long, don't bite yourself!",
      //component: SnakeGame
    },
    {
      //title: "Whack-a-Mole",
      //description: "Bop those moles as they pop up!",
      //component: WhackAMole
    },
    {
      title: "Memory Match",
      description: "Find the pairs and train your brain!",
      component: MemoryMatch
    }
  ];

  // ... (keep the rest of the App component the same)
}

export default App;